



























import useValidate from '@/use/validate'
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import Joi from 'joi'

export default defineComponent({
  setup(_, { root }) {
    const form = ref<any>(null)

    const { tlds, validate } = useValidate()

    const state = reactive({
      loading: false,
      user: computed(() => root.$store.getters['user/getData']),
      isFormValid: true
    })

    const model = reactive({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      smsNotifications: false
    })

    const updateModel = () => {
      model.firstName = state.user.firstName
      model.lastName = state.user.lastName
      model.email = state.user.email
      model.phone = state.user.phone,
      model.smsNotifications = state.user.smsNotifications
    }

    watch(() => state.user, updateModel, { deep: true })
    onMounted(updateModel)

    const rules: { [key: string]: Array<(v: any) => boolean | string> } = {
      firstName: [v => !!v || 'Podaj imię'],
      lastName: [v => !!v || 'Podaj nazwisko'],
      email: [
        v => !!v || 'Podaj email',
        v => !!validate(v, Joi.string().email({ tlds: { allow: tlds.map(d => d.toLowerCase()) } })) || 'Podaj prawidłowy adres email' 
      ]
    }

    const updateUser = () => {
      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      state.loading = true

      const data = {
        firstName: model.firstName || undefined,
        lastName: model.lastName || undefined,
        email: model.email || undefined,
        phone: model.phone || undefined,
        smsNotifications: model.smsNotifications || undefined
      }

      axiosInstance
        .put('user/current', data)
        .then(() => root.$store.dispatch('user/update'))
        .catch(console.log)
        .finally(() => state.loading = false)
    }

    const onSubmit = () => {
      form.value.validate()
      if (state.isFormValid) updateUser()
    }

    return { form, state, model, rules, onSubmit }
  }
})
